import React, { Component } from 'react';
import { Link } from 'gatsby';

import { ReactComponent as Arrow } from '../../images/down-arrow.svg';
import { ReactComponent as ArrowRight } from '../../images/arrow-right.svg';
import FancyLink from '../../utils/FancyLink.js';

import { MoreResultsWrapper } from './style';

class MoreResults extends Component {
	render() {
		return (
			<MoreResultsWrapper {...this.props}>
				<button onClick={this.props.moreResults}>
					<div className="arrow">{this.props.accommodation ? <ArrowRight /> : <Arrow />}</div>
					{this.props.accommodation ? (
						<FancyLink to={this.props.cta_link}>{this.props.cta_label} </FancyLink>
					) : this.props.events ? (
						'More Events'
					) : (
						'More Results'
					)}
				</button>

				{this.props.events && (
					<p>
						Are you an event organiser?{' '}
						<Link to="/whats-on/register-your-event/">Register your event</Link>
					</p>
				)}
			</MoreResultsWrapper>
		);
	}
}

export default MoreResults;
