import styled from 'styled-components';
import { device } from '../../utils/breakpoints';

export const SearchWrapper = styled.div`
	margin: 0;

	@media (${device.desktop}) {
		margin: 100px auto 0;
		max-width: 1200px;
	}
`;

export const TopLevel = styled.nav`
	display: block;

	border-bottom: 1px solid #212c1f;
	padding: 10px 20px;

	@media (${device.tablet}) {
		padding: 0;
		border-top: 1px solid #212c1f;
	}

	ul {
		display: flex;
		flex-direction: column;
		margin: 0;

		@media (${device.tablet}) {
			flex-direction: row;
			flex-wrap: wrap;
		}

		@media (${device.desktop}) {
			flex-direction: row;
			flex-wrap: nowrap;
		}
	}
`;

export const SecondLevel = styled.nav`
	display: block;
	background: #212c1f;
	color: #fff;
	padding: ${(props) => (props.visible ? '20px' : '0')};

	@media (${device.tablet}) {
		transition: 100ms;
		opacity: ${(props) => (props.visible ? '1' : '0')};
	}

	ul {
		display: grid;
		grid-template-columns: repeat(4, auto);
		grid-row-gap: 20px;
		margin: 0;

		@media (min-width: 1024px) {
			grid-template-columns: repeat(8, auto);
		}

		li {
			flex: 1;
			display: inline-flex;
			justify-content: center;
			align-items: center;
			padding: 20px;
		}
	}
`;

export const MoreOptionWrapper = styled.div`


	@media (min-width: 910px) {
		display: inline-block;
	}
`;

export const ResultCount = styled.div`
	display: flex;
	height: 32px;
	width: 32px;
	border: 1px solid #b9bab4;
	justify-content: center;
	align-items: center;
	margin-right: 15px;
	color: #212c1f;
	font-size: 14px;
	font-weight: 500;
	transition: 120ms;

	flex-shrink: 0;

	@media (${device.tablet}) {
		height: 40px;
		width: 40px;
		font-size: 16px;
		margin-right: 20px;
	}
`;

export const ResultsGrid = styled.div`
	display: flex;
	flex-wrap: wrap;

	div {
		width: 25%;
		display: inline-flex;
		flex-shrink: 0;
		padding: 20px;
		border: 1px solid red;
	}
`;

export const FilterTab = styled.li`
	color: ${(props) => (props.active ? '#8B3A19' : '#212C1F')};
	pointer-events: ${(props) => (props.count === 0 ? 'none' : 'auto')};
	opacity: ${(props) => (props.count === 0 ? '0.5' : '1')};
	position: relative;
	cursor: pointer;
	font-family: "Maison Neue", sans-serif;
	font-weight: 700;
	text-transform: uppercase;
	font-size: 12px;
	display: flex;
	align-items: center;
	padding: 10px 0;
	border-bottom: 1px solid #b9bab4;
	margin: 0;
	transition: 120ms;

	@media (${device.tablet}) {
		padding: 15px 0;
		border-bottom: none;
		font-size: 14px;
		width: calc(33.33% - 18px*2/3);
		flex-grow: 0;
		margin: 0 18px 0 0;

		&:nth-child(3n) {
			margin-right: 0;
		}

		&:nth-child(-n + 3) {
			border-bottom: 1px solid #b9bab4;
		}
	}

	@media (${device.desktop}) {
		width: auto;
		padding: 30px 0;
		margin: 0 0px 0 0;
		flex-grow: 1;
		border-bottom: none !important;
	}

	&:last-child {
		margin-right: 0;
		border-bottom: none;
	}

	&:before {
		content: "";
    opacity: 0;
		display: block;
		height: 5px;
		width: 100%;
		position: absolute;
		background: #8b3a19;
		top: -3px;

		@media (${device.tablet}) {
			transition: 90ms;
			opacity: ${(props) => (props.active ? '1' : '0')};
		}
	}

	${ResultCount} {
		background: ${(props) => (props.active ? '#8B3A19' : 'none')};
		border: ${(props) => (props.active ? '1px solid #8B3A19' : 'auto')};
		color: ${(props) => (props.active ? '#fff' : 'auto')};

		&:after {
			content: " ";
			display: none;
			bottom: 0;
			height: 0;
			width: 0;
			position: absolute;
			border-top: 0;
			border-bottom: 5px solid #212c1f;
			border-right: 5px solid transparent;
			border-left: 5px solid transparent;

			@media (${device.desktop}) {
				display: ${(props) => (props.active ? 'block' : 'none')};
			}
		}
	}

	:hover {
		color: #8b3a19;
		> div {
			border: 1px solid #8b3a19;
		}
	}
`;

export const EmptySearch = styled.div`
	background: pink;
	padding: 50px;
	font-weight: bold;
	color: red;
`;

export const MoreOption = styled.div`
	display: inline-flex;
  position:relative;
	color: ${(props) => (props.active ? '#8B3A19' : 'auto')};
	cursor: pointer;
	margin: 10px 10px 10px 0;
	font-weight: 500;
	font-size: 12px;
	padding: 2px 0;
	margin-right: 20px;

  ::after {
    content:'';
    position:absolute;
    bottom:-3px;
    width:100%;
    height: 2px;
    transition: 130ms;
    opacity: ${(props) => (props.active ? '1' : '0')};
    background:#8B3A19;
  }

	@media (${device.tablet}) {
		font-size: 14px;
  	margin-right: 18px;

	}

  @media(min-width: 910px) {
    margin-right: 20px;
  }

	.icon {
		height: 16px;
		width: 16px;
		display: inline-flex;
		position: relative;
		top: 0px;
		margin-right: 10px;

		@media (${device.tablet}) {
			top: 2px;
		}
	}

	img {
		border: 0;
		object-fit: contain;
		object-position: center;
		display: block;
		margin: 0;
	}

	:hover {
		color: #8b3a19;
	}
`;

export const MoreOptions = styled.div`
	font-family: "Maison Neue", sans-serif;
	padding: 23px 20px 0;

	@media (${device.tablet}) {
		padding: 23px 0;
	}

	.title {
		font-weight: 700;
		font-size: 10px;
		color: #212c1f;
		text-transform: uppercase;
		display: block;
		margin-bottom: 15px;

		@media (${device.tablet}) {
			display: inline-block;
			margin-right: 25px;
			font-size: 12px;
			margin-bottom: 4px;
		}

    @media (min-width:910px) {
			margin-bottom: 25px;
		}
	}
`;

export const LevelTwoFilter = styled.div`
	color: #fff;
	cursor: pointer;
	font-family: "Maison Neue", sans-serif;
	display: flex;
	align-items: flex-start;
	/* margin-right: 40px; */
	align-items: center;

	@media (min-width: 1024px) {
		justify-content: center;
	}

	.count {
		display: inline-flex;
		color: rgba(255, 255, 255, 0.5);
		margin-right: 10px;
		position: relative;
		text-decoration: none !important;
	}

	h6 {
		font-weight: 500;
		margin: 0;
		color: #fff;
		position: relative;
		font-size: 12px;
		display: inline;

		:after {
			content: "";
			bottom: -3px;
			left: 0;
			position: absolute;
			height: 1.3px;
			background-color: #ffffff;
			z-index: 3;
			width: 100%;
			transition: ${(props) => (props.active ? '0' : '140ms')};
			opacity: ${(props) => (props.active ? '1' : '0')};
			display: block;
		}
	}

	:hover {
		h6 {
			:after {
				opacity: ${(props) => (props.active ? '1' : '0.4')};
			}
		}
	}
`;

export const SearchHeader = styled.header`
	display: flex;
	flex-direction: column;
	align-items: stretch;

	@media (${device.tablet}) {
		flex-direction: row;
		align-items: center;
		padding: 0 0 30px;
	}

	h1 {
		font-size: 36px;
		padding: 0;
		margin: 0;

		@media (${device.tablet}) {
			font-size: 64px;
			line-height: 65px;
			padding-right: 28px;
		}

		@media (${device.desktop}) {
			font-size: 72px;
			line-height: 65px;
			padding-right: 35px;
		}
	}

	.leader {
		display: block;
		font-size: 12px;
		margin-bottom: 9px;

		@media (${device.tablet}) {
			font-size: 14px;
		}
	}

	input {
		background: none;
		color: #212c1f;
		border: none;
		font-family: "Freight Big", serif;
		font-size: 24px;
		width: 100%;
		display: block;
		padding: 0;
		outline: none;

		@media (${device.tablet}) {
			font-size: 36px;
		}

		&::placeholder {
			color: #b9bab4;
		}
	}

	.query {
		flex: 1;
		border-top: 1px solid #212c1f;
		border-bottom: 1px solid #212c1f;
		padding: 20px 0;
		padding-left: 8px;
		margin: 22px 0;

		@media (${device.tablet}) {
			border-bottom: none;
			border-top: none;
			border-left: 1px solid #b9bab4;
			padding: 0 0 0 23px;
			margin: 0;
		}

		@media (${device.desktop}) {
			padding: 0 0 0 35px;
		}
	}

	.inputwrapper {
		display: flex;
		align-items: center;

		svg {
			height: 20px;
			width: 20px;
			margin: 0 20px 0 0;

			@media (${device.tablet}) {
				height: 24px;
				width: 24px;
			}
		}
	}
`;

// export const MoreResults = styled.div`
//   margin-top: 30px;
//   border-top: 1px solid #545C52;
//   display: flex;
//   justify-content: center;

//   .arrow{
//     height: 40px;
//     width: 40px;
//     border: 1px solid #545C52;
//     background: #fff;
//     display: flex;
//     justify-content: center;
//     align-items: center;

//     img{
//       display: block;
//       margin: 0;
//     }
//   }

//   button {
//     margin-top: -20px;
//     display: flex;
//     flex-direction: column;
//     height: 80px;
//     width: 100px;
//     border: 0;
//     background: transparent;
//     justify-content: space-between;
//     align-items: center;
//     color: #545C52;
//     font-family: "Maison Neue", sans-serif;
//     text-transform: uppercase;
//     font-size: 14px;
//     padding: 0;
//     font-weight: 500;
//     cursor: pointer;

//     &:hover .arrow{
//       /* background: red; */
//     }
//   }
// `

export const MobilePanel = styled.div`
	display: ${(props) => (props.open ? 'block' : 'none')};
	background: #fbf9f6;
	box-shadow: 0 15px 50px 0 rgba(0, 0, 0, 0.15);
	padding-bottom: 20px;
	margin-bottom: 20px;

	@media (${device.tablet}) {
		box-shadow: none;
		display: block;
		background: none;
		margin-bottom: 0px;
	}
`;

export const MobileRefine = styled.div`
	display: flex;
	align-items: center;
	font-family: "Maison Neue", sans-serif;
	font-weight: 700;
	color: #212c1f;
	justify-content: space-between;
	text-transform: uppercase;
	padding-bottom: 22px;
	border-bottom: 1px solid #212c1f;
	margin-bottom: ${(props) => (props.open ? '0' : '17px')};

	@media (${device.tablet}) {
		display: none;
	}

	.title {
		font-size: 14px;
		display: flex;
		align-items: center;

		img {
			display: inline-block;
			margin: 0;
			width: 16px;
			margin: 0 10px;
			transform: scaleY(${(props) => (props.open ? -1 : 1)});
			position: relative;
			top: -1px;
		}
	}

	.selected {
		font-size: 10px;
		color: #8b3a19;
		position: relative;
		top: -3px;
	}

	${ResultCount} {
		margin: 0 0 0 15px;
		display: inline-flex;
		background: #8b3a19;
		border: 1px solid #8b3a19;
		color: #fff;
	}
`;

export const CloseMobilePanel = styled.div`
	font-family: "Maison Neue", sans-serif;
	font-weight: 700;
	color: #212c1f;
	border: 1px solid #212c1f;
	padding: 20px;
	margin: 20px;
	margin-bottom: 0;
	background: transparent;
	border-radius: 0;
	display: flex;
	justify-content: center;
	text-transform: uppercase;
	font-size: 14px;

	@media (${device.tablet}) {
		display: none;
	}
`;

export const ClearWrap = styled.div`
	margin-top: 20px;
	padding-top: 10px;
	border-top: 1px solid #b9bab4;

	@media (${device.tablet}) {
		border-left: 1px solid #b9bab4;
		border-top: none;
		display: inline-block;
		padding: 0 0 0 15px;
		margin: 0;
	}
`;
