import React from 'react'
import algoliasearch from 'algoliasearch'
import queryString from 'query-string'

import { MoreResults } from '../MoreResults'
import {
  SearchWrapper,
  SearchHeader,
  TopLevel,
  SecondLevel,
  ResultCount,
  ClearWrap,
  FilterTab,
  MoreOptionWrapper,
  LevelTwoFilter,
  MoreOptions,
  MoreOption,
  MobileRefine,
  MobilePanel,
  CloseMobilePanel,
} from './style'
import { Teaser } from '../Teaser'
// import { Unearth } from '../Unearth';

import {
  ResultsContainer as PageWrapper,
  GridWrapper,
} from '../SearchResults/style'

import pet from '../../images/pet.svg'
import accessible from '../../images/accessible.svg'
import skateboard from '../../images/skateboard.svg'
import ticket from '../../images/ticket.svg'
import arrow from '../../images/down-arrow.svg'
import wifi from '../../images/wifi.svg'

import { ReactComponent as Search } from '../../images/search.svg'
import clear from '../../images/clear.svg'

const TopLevelType = ['Events', 'Accommodation', 'Food & Drink', 'Attractions']
const MoreTypes = ['Itineraries', 'Stories', 'Makers & Growers']

const resultsPerPage = 8

class SearchPage extends React.Component {
  constructor(props) {
    super(props)
    this.indexName = `${process.env.GATSBY_ALGOLIA_INDEX_NAME_PREFIX}_posts`
    this.query = React.createRef()
    this.algoliasearch = this.initClient()
    this.index = this.algoliasearch.initIndex(this.indexName)
    this.state = {
      loadUnearth: false,
      query: '',
      results: [],
      filter: 'All',
      subFilters: [],
      subfilter: '',
      currentPage: 0,
      totalPages: 0,
      options: [],
      counts: {
        All: 0,
        Events: 0,
        Accommodation: 0,
        Food_Drink: 0,
        Attractions: 0,
        More: 0,
      },
      mobileOpen: false,
      _n_subfilters: Math.random(),
      _n_results: Math.random(),
      _n_counts: Math.random(),
    }
  }

  componentDidMount() {
    // initial results
    this.setState(
      {
        query: queryString.parse(document.location.search).q || '',
      },
      () => this.updateSearch('query')
    )

    this.setFilter('All')

    setTimeout(() => {
      this.setState({
        loadUnearth: true,
      })
    }, 400)
  }

  getSubFilters(filter, query) {
    let nonce = this.nonce('subfilters')

    let facetFilters = [`type:${filter}`]

    const filters = this.getFilterString(filter)

    //If 'More' get subfilters with results for query
    if (filter === 'More') {
      facetFilters = [
        MoreTypes.map(type => {
          return `type:${type}`
        }),
      ] //OR
    }

    this.index.searchForFacetValues(
      {
        facetName: 'type',
        facetQuery: '',
        query: query,
        filters: filters,
        facetingAfterDistinct: true,
        facetFilters,
      },
      (err, res) => {
        if (err) {
          console.log(err)
          return
        }

        //get subfilter names
        let subFilters = res.facetHits.filter(facet => {
          if (filter === 'More') {
            return MoreTypes.indexOf(facet.value) !== -1
          }

          return TopLevelType.indexOf(facet.value) === -1
        })

        //this should only be called if it was the most recently initiatel getSubFilters call
        if (this.latestNonce(nonce, 'subfilters')) {
          this.setState({
            subFilters,
          })
        }
      }
    )
  }

  getFilterString(filter) {
    const type = filter === 'Events' ? 'Events' : 'Operators'
    const today = Date.parse(Date()) / 1000

    let filters = `bs=1 AND post_type_label:${type} AND event_enddate >= ${today}`

    console.log(filters)
    if (filter === 'More') {
      return (filters = `event_enddate >= ${today}`)
    }

    return filters
  }

  getTopLevelCounts(query) {
    let nonce = this.nonce('counts')

    const today = Date.parse(Date()) / 1000

    this.index.searchForFacetValues(
      {
        facetName: 'type',
        facetQuery: '',
        filters: `event_enddate >= ${today}`,
        query,
        facetingAfterDistinct: true,
      },
      (err, res) => {
        if (err) {
          console.log(err)
          return
        }

        let counts = this.state.counts

        console.log(res)

        //set all topLevel counts to 0
        TopLevelType.map(type => {
          return (counts[type] = 0)
        })

        //Update top level counts
        res.facetHits
          .filter(hit => {
            return TopLevelType.indexOf(hit.value) > -1 ? hit : false
          })
          .map(type => {
            return (counts[type.value] = type.count)
          })

        //Get More counts
        counts['More'] = res.facetHits.reduce((acc, hit) => {
          return MoreTypes.indexOf(hit.value) > -1 ? hit.count + acc : acc
        }, 0)

        //only update state for latest counts call
        if (this.latestNonce(nonce, 'counts')) {
          this.setState({
            counts,
          })
        }
      }
    )
  }

  populateSearch(query, type, subfilter, options, page = 0, ignoreNonce) {
    let nonce = this.nonce('results')

    const today = Date.parse(Date()) / 1000

    //to get all results count
    let all = {
      indexName: this.indexName,
      query,
      params: {
        hitsPerPage: 1,
        page: 0,
        filters: `event_enddate >= ${today}`,
      },
    }

    //filtered results to display
    let filtered = {
      indexName: this.indexName,
      query,
      params: {
        hitsPerPage: resultsPerPage,
        page,
        filters: `event_enddate >= ${today}`,
      },
    }

    if (type !== 'All') {
      filtered.params.facetFilters = [`type:${type}`]
      console.log(type)
    }

    //remove events that end before today
    // if (filtered.params.facetFilters = [`type:Events`]) {
    // 	filtered.params.filters = `event_enddate >= ${today}`
    // }

    //If more selected, get results for moretypes
    if (type === 'More') {
      let facetOrFilters = MoreTypes.map(type => {
        return `type:${type}`
      })

      filtered.params.facetFilters = [
        facetOrFilters, //OR
      ]
    }

    if (subfilter) {
      if (type === 'More') {
        //just search on subfilter
        filtered.params.facetFilters = [`type:${subfilter.value}`]
      } else {
        filtered.params.facetFilters = [
          `type:${type}`, //AND
          `type:${subfilter.value}`,
        ]
      }
    }

    if (options.length) {
      let opt = options.map(option => {
        return `filters:${option}`
      })

      if (filtered.params.facetFilters) {
        filtered.params.facetFilters = [...filtered.params.facetFilters, ...opt]
      } else {
        filtered.params.facetFilters = [opt]
      }
    }

    if (filtered.params.facetFilters) {
      filtered.params.facetFilters.push('type: -Industry Hub')
    } else {
      filtered.params.facetFilters = ['type: -Industry Hub']
    }

    this.algoliasearch.search([all, filtered], (err, res) => {
      if (err) {
        console.log(err)
        return
      }

      let results = []

      if (page > 0) {
        //append results
        results = [...this.state.results, ...res.results[1].hits]
      } else {
        //fill results
        results = res.results[1].hits
      }

      //only update results with latest call
      if (ignoreNonce || this.latestNonce(nonce, 'results')) {
        this.setState({
          counts: {
            ...this.state.counts,
            All: res.results[0].nbHits || 0,
          },
          totalPages: res.results[1].nbPages,
          results, //: res.results[1].hits //this needs to be updated to support pagination
        })
      }
    })
  }

  clearCounts(clearAll) {
    let counts = this.state.counts

    //set all topLevel counts to 0
    TopLevelType.map(type => {
      return (counts[type] = 0)
    })

    counts['More'] = 0

    //clear all count (used for empty query)
    if (clearAll) {
      counts['All'] = 0
    }

    this.setState({
      counts,
    })
  }

  clearResults() {
    this.setState({
      results: [],
    })
  }

  //SEARCH ACTIONS

  queryChange(e) {
    this.setState(
      {
        query: e.target.value,
        currentPage: 0,
        totalPages: 0,
      },
      () => this.updateSearch('query')
    )
  }

  clearOptions() {
    this.setState(
      {
        options: [],
        currentPage: 0,
        totalPages: 0,
      },
      () => this.updateSearch('options')
    )
  }

  toggleOption(option) {
    let currentOptions = this.state.options
    let idx = currentOptions.indexOf(option)

    if (idx > -1) {
      currentOptions.splice(idx, 1)
    } else {
      currentOptions.push(option)
    }

    this.setState(
      {
        options: currentOptions,
        currentPage: 0,
        totalPages: 0,
      },
      () => this.updateSearch('options')
    )
  }

  setFilter(filter) {
    this.setState(
      {
        filter,
        currentPage: 0,
        totalPages: 0,
        subfilter: '', //clear subfilter
        // subFilters: [] //clear subfilters // removed to stop the flash, there's a <1s  delay before the new sub filter query completes but not sure if it's enough to justify a loading state
      },
      () => this.updateSearch('filter')
    )

    console.log(filter)
  }

  setSubFilter(subfilter) {
    this.setState(
      {
        subfilter,
        currentPage: 0,
        totalPages: 0,
      },
      () => this.updateSearch('subfilter')
    )

    console.log(subfilter)
  }

  updateSearch(type) {
    let { query, filter, subfilter, options, currentPage } = this.state

    this.populateSearch(
      query,
      filter,
      subfilter,
      options,
      currentPage,
      type === 'moreResults'
    )

    //just update filtered results, filters and counts remain same
    if (type === 'moreResults') {
      return
    }

    this.getSubFilters(filter, query)

    if (type === 'query') {
      this.getTopLevelCounts(query)
    }
  }

  moreResults() {
    this.setState(
      {
        currentPage: this.state.currentPage + 1,
      },
      () => this.updateSearch('moreResults')
    )
  }

  //stole nonce idea from here https://dev.to/chromiumdev/cancellable-async-functions-in-javascript-5gp7
  nonce(func) {
    let ns = {}
    ns['_n_' + func] = {} //new Object()

    this.setState({
      ...ns,
    })

    // console.log(`set ${func} nonce`)

    return ns['_n_' + func]
  }

  latestNonce(nonce, func) {
    // console.log(nonce === this.state['_n_'+func] ? `latest ${func} nonce` : `not latest ${func} call`, nonce, this.state['_n_'+func])
    return nonce === this.state['_n_' + func]
  }

  toggleRefine() {
    this.setState({
      mobileOpen: !this.state.mobileOpen,
    })
  }

  render() {
    return (
      <PageWrapper>
        <SearchWrapper>
          <SearchHeader>
            <h1>Search</h1>
            <div className="query">
              <span className="leader">So much to discover</span>
              <div className="inputwrapper">
                <Search />
                <input
                  ref={this.query}
                  placeholder="Try searching for ‘art’ or ‘wine’"
                  value={this.state.query}
                  onChange={this.queryChange.bind(this)}
                />
              </div>
            </div>
          </SearchHeader>
          <MobileRefine
            open={this.state.mobileOpen}
            onClick={this.toggleRefine.bind(this)}
          >
            <div className="title">
              <img src={arrow} alt="" /> Refine results
            </div>
            <div className="selected">
              {this.state.filter !== 'All' ? this.state.filter : 'Viewing All'}
              <ResultCount>{this.state.counts[this.state.filter]}</ResultCount>
            </div>
          </MobileRefine>
          <MobilePanel open={this.state.mobileOpen}>
            <TopLevel>
              <ul>
                <TypeFilter
                  click={this.setFilter.bind(this)}
                  label="View All"
                  state={this.state}
                  filter="All"
                />
                {TopLevelType.map(type => (
                  <TypeFilter
                    key={`tl-${type}`}
                    click={this.setFilter.bind(this)}
                    state={this.state}
                    filter={type}
                  />
                ))}
                <TypeFilter
                  click={this.setFilter.bind(this)}
                  state={this.state}
                  filter="More"
                />
              </ul>
            </TopLevel>
            <SecondLevel visible={this.state.filter !== 'All'}>
              <ul>
                {this.state.subFilters.map(subfilter => {
                  return (
                    <SubFilter
                      click={this.setSubFilter.bind(this)}
                      key={`sf-${subfilter.value}`}
                      subfilter={subfilter}
                      state={this.state}
                    />
                  )
                })}
              </ul>
            </SecondLevel>

            <MoreOptions>
              <span className="title">More options</span>
              <MoreOptionWrapper>
                <MoreOptionX
                  img={accessible}
                  option="Accessible"
                  click={this.toggleOption.bind(this)}
                  state={this.state}
                />
                <MoreOptionX
                  img={skateboard}
                  option="Kid Friendly"
                  click={this.toggleOption.bind(this)}
                  state={this.state}
                />
                <MoreOptionX
                  img={ticket}
                  option="Free Entry"
                  click={this.toggleOption.bind(this)}
                  state={this.state}
                />
                <MoreOptionX
                  img={pet}
                  option="Pet Friendly"
                  click={this.toggleOption.bind(this)}
                  state={this.state}
                />
                <MoreOptionX
                  img={wifi}
                  option="Internet Access"
                  click={this.toggleOption.bind(this)}
                  state={this.state}
                />

                {this.state.options.length > 0 && (
                  <ClearWrap>
                    <MoreOptionX
                      img={clear}
                      option="Clear"
                      click={this.clearOptions.bind(this)}
                      state={this.state}
                    />
                  </ClearWrap>
                )}
              </MoreOptionWrapper>
            </MoreOptions>
            <CloseMobilePanel onClick={this.toggleRefine.bind(this)}>
              Close and show results
            </CloseMobilePanel>
          </MobilePanel>
          <GridWrapper>
            {this.state.results.map(result => {
              console.log('result:', result)
              return (
                <Teaser
                  openInNewTab
                  search
                  key={result.post_id}
                  date={result.type && result.type.join(', ')}
                  img={{
                    ...result.img,
                    sizes: '300px',
                  }}
                  url={result.permalink}
                  title={result.post_title}
                  region={result.region}
                  desc={result._snippetResult.content.value}
                  categories={result.type}
                />
              )
            })}
          </GridWrapper>
          {this.state.currentPage + 1 < this.state.totalPages && (
            <MoreResults
              moreResults={this.moreResults.bind(this)}
              label="results"
            />
          )}
        </SearchWrapper>

        {/* {this.state.loadUnearth && <Unearth variation />} */}
      </PageWrapper>
    )
  }

  initClient() {
    let key = process.env.GATSBY_ALGOLIA_SEARCH_API_KEY,
      id = process.env.GATSBY_ALGOLIA_APP_ID

    return algoliasearch(id, key)
  }
}

const TypeFilter = ({ click, filter, state, label }) => {
  return (
    <FilterTab
      hasSubfilters={state.subFilters.length && filter === state.filter}
      active={filter === state.filter}
      count={state.counts[filter]}
      onClick={() => click(filter)}
    >
      <ResultCount>{state.counts[filter]}</ResultCount> {label || filter}
    </FilterTab>
  )
}

const SubFilter = ({ subfilter, state, click }) => {
  return (
    <LevelTwoFilter
      active={subfilter.value === state.subfilter.value}
      onClick={() => click(subfilter)}
    >
      <span className="count">{subfilter.count}</span>
      <h6>{subfilter.value}</h6>
    </LevelTwoFilter>
  )
}

const MoreOptionX = ({ option, img, click, state }) => {
  return (
    <MoreOption
      onClick={() => click(option)}
      active={state.options.indexOf(option) > -1}
    >
      <div className="icon">
        <img src={img} alt={option} />
      </div>{' '}
      {option}
    </MoreOption>
  )
}

export default SearchPage
