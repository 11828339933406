import React from 'react';
import Layout from '../components/layout';
import SEO from '../components/seo';

import { SearchPage } from '../components/SearchPage';

const Search = () => (
	<Layout generic>
			<SEO title="Search" />
      <SearchPage />
	</Layout>
);

export default Search;