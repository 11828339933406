import styled from 'styled-components'

export const MoreResultsWrapper = styled.div`
  transform: translateY(-2px);
  position: relative;
  z-index: 100;

  /* border-top: 1px solid #545C52; */
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  p {
    font-size: 12px;

    position: relative;
    margin: 0;
    display: inline-block;

    /* display: none; */

    a {
      font-weight: 500;
      :hover {
        color: #a55331;
      }
    }

    @media (min-width: 768px) {
      position: absolute;
      bottom: 19px;
      right: 0;
      display: block;
    }

    @media (min-width: 1024px) {
      font-size: 14px;
    }
  }

  .arrow {
    height: 40px;
    width: 40px;
    border: 1px solid #545c52;
    background: #fbf9f6;
    display: flex;
    justify-content: center;
    align-items: center;

    img {
      display: block;
      margin: 0;
      width: 16px;
    }
  }

  button {
    /* margin-top: -20px; */
    outline: none;
    display: flex;
    flex-direction: column;
    height: 80px;
    width: auto;
    border: 0;
    background: transparent;
    justify-content: space-between;
    align-items: center;
    color: #545c52;
    font-family: 'Maison Neue', sans-serif;
    text-transform: uppercase;
    font-size: 14px;
    padding: 0;
    font-weight: 500;
    transform: translateY(-25%);
    position: relative;
    cursor: pointer;

    :hover {
      .arrow {
        border: 1px solid #a55331;
      }

      svg {
        path {
          fill: #a55331;
        }
      }
    }

    svg {
      width: ${props => (props.accommodation ? '11px' : '16px')};
    }
  }

  a {
    ::before {
      position: absolute;
      content: '';
      height: 100%;
      width: 100%;
      left: 0;
      top: 0;
    }
  }
`
