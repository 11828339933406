import styled from 'styled-components';

export const ResultsContainer = styled.div`
	padding: ${(props) => props.theme.globalPaddingMobile};
	margin: 20px 0;


	@media (min-width: 768px) {
		padding: 0 30px;
	}

	@media (min-width: 1280px) {
		padding: ${(props) => props.theme.globalPaddingDesktop};
	}
`;

export const GridWrapper = styled.div`
	display: grid;
	overflow: hidden;
	grid-template-columns: 1fr 1fr;
	grid-row-gap: 20px;
	grid-column-gap: 15px;	


	@media (min-width: 768px) {
		grid-gap: 30px;

		grid-template-columns: 1fr 1fr 1fr;

	} 
	
	

	@media (min-width: 1280px) {
		grid-template-columns: 1fr 1fr 1fr 1fr;
		grid-gap: 55px;


	}




`;
